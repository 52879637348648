* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

::-webkit-scrollbar-track {
  /* border-radius: 5px; */
}
::-webkit-scrollbar {
  width: 7px;
}
::-webkit-scrollbar-thumb {
  /* background-color: #0236109e; */
  background-color: black;
  border-radius: 2px;
}
