@import url("https://fonts.googleapis.com/css2?family=Advent+Pro:wght@100&display=swap");
.footer-section {
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0px;
}

.footer-section .footer-section-icons {
  font-size: 21px;
  display: flex;
  transform: scale(0.7);
}
.footer-section
  .footer-section-icons
  .footer-section-icon-wrapper:nth-child(1) {
  margin-right: 5px;
}
.footer-section p {
  text-align: center;
  padding: 5px;
  font-size: 15px;
  font-family: "Advent Pro", sans-serif;
  font-weight: bolder;
}

.footer-section-icon-wrapper a {
  cursor: pointer;
  color: black;
  height: 40px;
  width: 40px;
  border: 1px solid black;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
