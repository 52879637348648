@import url("https://fonts.googleapis.com/css2?family=Barriecito&display=swap");

.slider-wrapper {
  margin: 0 auto;
  width: 80%;
}
.carousel-container-header {
  color: black;
  margin-bottom: 40px;
}
.carousel-container-header :nth-child(2) {
  font-size: 33px;
  font-family: "Abril Fatface", cursive;
  text-align: center;
}
.carousel-container-header :nth-child(1) {
  font-size: 20px;
  text-align: center;
  font-family: "Abril Fatface", cursive;
}
.slick-next:before,
.slick-prev:before {
  color: #023610bc;
  opacity: 1;
}

.slick-dots li button:before,
.slick-dots li.slick-active button:before {
  color: #023610bc;
}

@media screen and (min-width: 600px) {
  .carousel-container-header :nth-child(2) {
    font-size: 38px;
  }
  .carousel-container-header :nth-child(1) {
    font-size: 21px;
  }
}
@media screen and (min-width: 900px) {
  .carousel-container-header :nth-child(2) {
    font-size: 40px;
  }
  .carousel-container-header :nth-child(1) {
    font-size: 22px;
  }
}
