@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Advent+Pro:wght@100&display=swap");

.contact-container {
  color: white;
  position: relative;
}

.contact-container-header {
  color: black;
  margin-bottom: 40px;
}
.contact-container-header :nth-child(2) {
  font-size: 33px;
  font-family: "Abril Fatface", cursive;
  text-align: center;
}
.contact-container-header :nth-child(1) {
  font-size: 20px;
  text-align: center;
  font-family: "Abril Fatface", cursive;
}

.contact-container-content {
  padding: 20px;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
}
.contact-container-content p,
.contact-container-content label,
.form-btn h3,
textarea::placeholder,
input::placeholder {
  font-family: "Advent Pro", sans-serif;
  font-weight: bolder;
}
.contact-container-wrapper {
  height: 100%;
}
.contact-container-icons {
  width: 100%;
  max-width: 470px;
  margin: 0 auto;
  height: 100%;
}

.icon-wrapper {
  padding: 8px;
  margin-bottom: 8px;
  align-items: center;
  color: black;
}

.icon-circle {
  margin-bottom: 5px;
  border: 1px solid black;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background-color: white;
  border-radius: 50%;
}

.circleSwap {
  border-radius: 6px;
  padding: 8px;
}

.contact-container-inputfields {
  width: 100%;
  height: 100%;
  max-width: 470px;
  margin: 0 auto;
  margin-top: 20px;
}
.form-wrapper {
  display: flex;
  flex-direction: column;
}

.input-wrapper {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}
.formLabel {
  position: absolute;
  color: rgb(3, 3, 3);
  background-color: white;
  top: -10px;
  left: 25px;
}
.form-wrapper-icon {
  position: absolute;
  right: 10px;
  top: 15px;
  color: black;
}
.form-wrapper textarea,
.form-wrapper input {
  padding: 15px 15px 15px 25px;
  width: 100%;
  border-radius: 30px;
  border: 1px solid black;
  font-family: "Advent Pro", sans-serif;
  color: black;
  font-weight: bolder;
  letter-spacing: 1px;
}

.location-wrapper {
  align-items: center;
  justify-content: space-between;
}

.swap-icon {
  cursor: pointer;
}

.form-btn {
  width: 98px;
  height: 40px;
  cursor: pointer;
  border-radius: 20px;
  background-color: white;
  border: 1px solid black;
  color: black;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  transition: 0.5s;
}
.input-wrapper input,
.input-wrapper textarea {
  background-color: white;
  outline: none;
}
.form-btn:hover {
  transition: 0.5s;
  color: white;
  background: rgb(2, 54, 16);
  background: linear-gradient(
    270deg,
    rgba(2, 54, 16, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}

.contact-modal {
  width: 250px;
  padding: 10px;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  position: absolute;
  color: white;
  background-color: #023610;
  left: 50%;
  top: 50%;
  transform: translate(-50%, 150%);
}

.contact-modal p {
  text-align: center;
  margin: 10px;
  font-size: 15px;
}
.contact-modal-icon {
  font-size: 30px;
}
.contact-modal-btn {
  height: 30px;
  width: 80px;
  margin: 10px;
  background-color: white;
  border-radius: 5px;
  cursor: pointer;
  border: none;
}
@media screen and (min-width: 500px) {
  .location-wrapper {
    display: flex;
  }
  .icon-wrapper {
    display: flex;
  }
}

@media screen and (min-width: 600px) {
  .contact-container-header :nth-child(2) {
    font-size: 38px;
  }
  .contact-container-header :nth-child(1) {
    font-size: 21px;
  }
}
@media screen and (min-width: 900px) {
  .contact-container-header :nth-child(2) {
    font-size: 40px;
  }
  .contact-container-header :nth-child(1) {
    font-size: 22px;
  }
}

@media screen and (min-width: 1000px) {
  .contact-container-wrapper {
    display: flex;
  }
  .contact-container-inputfields {
    padding: 10px 0px 0px 20px;
  }
  .contact-container-icons {
    width: 50%;
  }
  .contact-container-content {
    width: 95%;
    position: relative;
  }
  .contact-modal {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
