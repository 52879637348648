@import url("https://fonts.googleapis.com/css2?family=Advent+Pro:wght@100&display=swap");
.about-section {
  margin-top: 80px;
}

.about-section-info-header {
  color: black;
}
.about-section-info-header :nth-child(2) {
  font-size: 33px;
  font-family: "Abril Fatface", cursive;
  text-align: center;
}
.about-section-info-header :nth-child(1) {
  font-size: 20px;
  text-align: center;
  font-family: "Abril Fatface", cursive;
}

.honeycomb {
  margin: 0px auto 200px auto;
  display: flex;
  flex-wrap: wrap;
  list-style: none;

  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  max-width: 1200px;
  padding: 0;
  transform: translateY(80px);
}

.honeycomb-cell {
  cursor: pointer;
  -webkit-box-flex: 0;
  flex: 0 1 250px;
  max-width: 250px;
  height: 137.5px;
  margin: 65.4px 12.5px 25px;
  position: relative;
  padding: 0.5em;
  text-align: center;
}
.honeycomb-cell:hover .honeycomb-cell-content {
  transform: scale(1.2);
  border-radius: 5px;
  padding: 15px;
  font-size: 13px;
  border: 2px solid rgb(255, 255, 255);
  z-index: 11111;
  background-color: rgba(0, 0, 0, 0.626);
}
.cert-content-p {
  font-style: oblique;
  letter-spacing: 1px;
}

.honeycomb-cell_img {
  object-fit: cover;
  object-position: center;
  filter: grayscale(100%);
}
.skill-icon {
  height: 20px;
  margin: 10px;
}

.about-wrapper {
  display: flex;
  justify-content: space-around;
}

.honeycomb-cell_title {
  font-size: 20px;
  font-family: "Advent Pro", sans-serif;
  height: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-hyphens: auto;
  hyphens: auto;
  word-break: break-word;
  text-transform: uppercase;
  color: #fff;
  font-weight: 700;
  transition: opacity 350ms;
}
.honeycomb-cell_quote {
  font-family: "Advent Pro", sans-serif;
  font-size: 13px;
  height: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-hyphens: auto;
  hyphens: auto;
  word-break: break-word;
  text-transform: uppercase;
  color: #fff;
  font-weight: 700;
  line-height: 25px;
  transition: opacity 350ms;
}

.honeycomb-cell::before,
.honeycomb-cell::after {
  content: "";
}
.honeycomb-cell::before,
.honeycomb-cell::after,
.honeycomb-cell_img {
  top: -50%;
  left: 0;
  width: 100%;
  height: 200%;
  display: block;
  position: absolute;
  -webkit-clip-path: polygon(
    50% 0%,
    100% 25%,
    100% 75%,
    50% 100%,
    0% 75%,
    0% 25%
  );
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  z-index: -1;
}
.honeycomb-cell::before {
  background: #000000;
  transform: scale(1.055);
}
.honeycomb-cell::after {
  background-color: #0236109e;
  transition: opacity 350ms;
  -webkit-transition: opacity 350ms;
}
.honeycomb-cell:hover .honeycomb-cell_title {
  opacity: 0;
}
.honeycomb-cell:hover .honeycomb-cell_img {
  opacity: 0;
}

.honeycomb-cell-content {
  opacity: 0;

  transition: opacity 350ms;
  top: -10%;
  color: white;
  left: 0;
  width: 100%;
  position: absolute;
}
.honeycomb-cell-content p {
  font-size: 13px;
  font-family: "Advent Pro", sans-serif;
  font-weight: bold;
}
.honeycomb-cell-content span {
  font-family: "Advent Pro", sans-serif;
  font-weight: bold;
  font-style: oblique;
}
.honeycomb-cell-content a {
  color: white;
  text-decoration: none;
}
.cert-content a {
  font-family: "Advent Pro", sans-serif;
  font-weight: bold;
}

.honeycomb-cell:hover .honeycomb-cell-content {
  opacity: 1;
  transition: opacity 350ms;
}

.honeycomb-cell:hover::after {
  opacity: 1;
}
.honeycomb_Hidden {
  display: none;
  opacity: 0;
  width: 250px;
  margin: 0 12.5px;
}
@media screen and (min-width: 600px) {
  .about-section-info-header :nth-child(2) {
    font-size: 38px;
  }
  .about-section-info-header :nth-child(1) {
    font-size: 21px;
  }
}
@media screen and (min-width: 900px) {
  .about-section-info-header :nth-child(2) {
    font-size: 40px;
  }
  .about-section-info-header :nth-child(1) {
    font-size: 22px;
  }
}

@media (max-width: 550px) {
  .honeycomb-cell {
    margin: 81.25px 25px;
  }
}

@media (min-width: 550px) and (max-width: 825px) {
  .honeycomb-cell:nth-child(3n) {
    margin-right: calc(50% - 125px);
    margin-left: calc(50% - 125px);
  }

  .honeycomb_Hidden:nth-child(3n + 5) {
    display: block;
  }
}

@media (min-width: 825px) and (max-width: 1100px) {
  .honeycomb-cell:nth-child(5n + 4) {
    margin-left: calc(50% - 275px);
  }

  .honeycomb-cell:nth-child(5n + 5) {
    margin-right: calc(50% - 275px);
  }

  .honeycomb_Hidden:nth-child(5n),
  .honeycomb_Hidden:nth-child(5n + 3) {
    display: block;
  }
}
@media (min-width: 1100px) {
  .honeycomb-cell:nth-child(7n + 5) {
    margin-left: calc(50% - 400px);
  }

  .honeycomb-cell:nth-child(7n + 7),
  .honeycomb-cell:nth-child(7n + 5):nth-last-child(2) {
    margin-right: calc(50% - 400px);
  }

  .honeycomb_Hidden:nth-child(7n + 7),
  .honeycomb_Hidden:nth-child(7n + 9),
  .honeycomb_Hidden:nth-child(7n + 11) {
    display: block;
  }
}
