@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap");

.projects-section-header :nth-child(2) {
  font-size: 33px;
  font-family: "Abril Fatface", cursive;
  text-align: center;
}
.projects-section-header :nth-child(1) {
  font-size: 20px;
  text-align: center;
  font-family: "Abril Fatface", cursive;
}
.projects-section-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
@media screen and (min-width: 600px) {
  .projects-section-header :nth-child(2) {
    font-size: 38px;
  }
  .projects-section-header :nth-child(1) {
    font-size: 21px;
  }
}
@media screen and (min-width: 900px) {
  .projects-section-header :nth-child(2) {
    font-size: 40px;
  }
  .projects-section-header :nth-child(1) {
    font-size: 22px;
  }
}
