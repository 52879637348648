@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap");
.card-wrapper {
  height: 45vh;
  position: relative;
}
.card {
  width: 222px;
  height: 175px;
  position: relative;
  top: 0px;
  left: 0px;
  overflow: hidden;
  border-radius: 37px;
  cursor: pointer;
  margin: 20px;
  border-radius: 35px;
}
.card .card-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 35px;
  transition: transform 0.5s;
  filter: grayscale(1);
}
.card .layer {
  width: 100%;
  border-radius: 35px;
  height: 0;
  transition: height 0.7s;
  background: linear-gradient(rgba(255, 255, 255, 0.5), #0236109e);
  position: absolute;
  left: 0px;
  bottom: 0px;
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 40px;
  flex-direction: column;
}
.layer h5 {
  font-size: 35px;
  font-family: "Advent Pro", sans-serif;
  color: white;
}

.card:hover .layer {
  height: 100%;
  border-radius: 35px;
}

@media screen and (min-width: 400px) {
  .card {
    width: 300px;
    height: 200px;
  }
  .card-wrapper {
    position: relative;
    height: 39vh;
  }
}
