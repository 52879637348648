@import url("https://fonts.googleapis.com/css2?family=Advent+Pro:wght@100&display=swap");
.testimonialCard {
  height: 300px;
  margin: 0px 15px;
  color: black;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;
  border: 2px solid #023610bc;
}

.testimonialCard-wrapper {
  height: 100%;
  overflow-y: scroll;
  padding: 10px;
}

.testimonialCard-wrapper p,
.testimonialCard-wrapper h2 {
  font-family: "Advent Pro", sans-serif;
  font-weight: bolder;
}
.testimonialCard-text,
.testimonialCard-header {
  font-size: 14px;
}
.testimonialCard-header {
  font-size: 14px;
  font-style: oblique;
}
