@import url("https://fonts.googleapis.com/css2?family=Advent+Pro:wght@100&display=swap");

.expandingProjectCard-cardContainer {
  color: white;
  background-color: #0236109e;
  border: 2px solid black;
  width: fit-content;
  padding: 10px;
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 1;
  max-width: 251px;
}
.expandingProjectCard-btn {
  width: 20px;
  height: 20px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.expandingProjectCard-iconWrapper {
  display: flex;
  margin-top: 10px;
}
.expandingProjectCard-iconWrapper :nth-child(2) {
  margin-left: 7px;
}

.expandingProjectCard-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: white;
  border: 1px solid black;
  transition: 1s;
}
.expandingProjectCard-icon:hover {
  background-color: black;
  cursor: pointer;
  transition: 1s;
  border: 1px solid white;
}

.expandingProjectCard-icon :nth-child(1) {
  color: black;
  transition: 1s;
}
.expandingProjectCard-icon:hover :nth-child(1) {
  color: white;
  transition: 1s;
}
.expandingProjectCard-paragraph {
  font-size: 15px;
  font-family: "Advent Pro", sans-serif;
  font-weight: bold;
}

.expandingProjectCard-expand {
  min-height: 180px;
}

@media screen and (min-width: 400px) {
  .expandingProjectCard-cardContainer {
    max-width: none;
  }
}
