@import url("https://fonts.googleapis.com/css2?family=Advent+Pro:wght@100&display=swap");

#home {
  position: relative;
}
.home-container {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  filter: grayscale(100%);
  background-image: url(/public/images/coding.jpg);
  align-items: center;
  position: relative;
}

.home-text {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  color: white;
  width: 90%;
  height: 30%;
  letter-spacing: 13px;
  padding: 10px;
}
.home-text-wrapper h1 {
  font-size: 50px;
}
h1 {
  margin: 0 auto;
}
.home-text-wrapper h2 {
  color: #023610;
  font-size: 14px;
  background: rgb(2, 54, 16);
  background: linear-gradient(
    270deg,
    rgba(2, 54, 16, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  padding: 5px;
  margin: 10px 0px;
}
.home-text-wrapper p {
  font-size: 9px;
  color: white;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    253deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(2, 54, 16, 1) 100%
  );
}
.home-text p,
.home-text h1,
.home-text h2 {
  font-family: "Advent Pro", sans-serif;
}
.home-container-icon-wrapper {
  position: fixed;
  top: 15px;
  right: 15px;
  z-index: 1000;
}
.home-container-icon {
  border: 2px solid #023610;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  background-color: white;
}
@media screen and (min-width: 600px) {
  .home-text {
    position: absolute;

    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;

    color: white;
    width: 60%;
    height: 30%;
    letter-spacing: 13px;
    padding: 10px;
  }
  .home-text-wrapper h1 {
    font-size: 60px;
  }
  .home-text-wrapper h2 {
    font-size: 20px;
  }
  .home-text-wrapper p {
    font-size: 13px;
  }
}
@media screen and (min-width: 900px) {
  .home-text {
    position: absolute;

    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;

    color: white;
    width: 50%;
    height: 30%;
    letter-spacing: 13px;
    padding: 10px;
  }
  .home-text-wrapper h1 {
    font-size: 60px;
  }
  .home-text-wrapper h2 {
    font-size: 23px;
  }
  .home-text-wrapper p {
    font-size: 16px;
  }
}
