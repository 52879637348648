@import url("https://fonts.googleapis.com/css2?family=Advent+Pro:wght@100&display=swap");
.navigation-mob-wrapper {
  position: relative;
  width: 49px;
  top: 25px;
  left: 15px;
  position: fixed;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  z-index: 99;
  height: fit-content;
}
.navigation-mob {
  background-color: white;
  position: relative;
  border: 2px solid #023610;
  width: 49px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}
.navigation-mob-burger {
  position: absolute;
  top: -10px;
  border-radius: 50%;
  z-index: 100;
  color: #023610;
  background-color: white;
  border: 2px solid #023610;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
}

.navigation-mob-displayNone {
  display: none;
}

.navigation-mob .navigation-ul-mob {
  height: 350px;
}
.navigation-mob .navigation-ul-mob .navigation-list-mob {
  position: relative;
  list-style: none;
  width: 70px;
  height: 70px;

  text-justify: center;
  z-index: 1;
}
.navigation-mob .navigation-ul-mob .navigation-list-mob .navigation-link-mob {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  font-weight: 500;
  text-align: center;
}
.navigation-mob
  .navigation-ul-mob
  .navigation-list-mob
  .navigation-link-mob
  .navigation-icon-mob {
  position: relative;
  display: block;
  line-height: 75px;
  font-size: 1.5em;
  text-align: center;
  transition: 0.5s;
  color: black;
}

.navigation-mob
  .navigation-ul-mob
  .navigation-list-mob.active
  .navigation-link-mob
  .navigation-icon-mob {
  position: relative;
  display: block;
  line-height: 75px;
  font-size: 1.5em;
  text-align: center;
  transition: 0.5s;
  color: rgb(255, 255, 255);
}

.navigation-mob
  .navigation-ul-mob
  .navigation-list-mob.active
  .navigation-link-mob
  .navigation-icon-mob {
  transform: translatex(35px);
}
.navigation-mob
  .navigation-ul-mob
  .navigation-list-mob
  .navigation-link-mob
  .navigation-text-mob {
  position: absolute;
  color: black;
  font-size: 0.75em;
  letter-spacing: 0.05em;
  transition: 0.5s;
  opacity: 0;
  transform: translatex(10px);
  writing-mode: vertical-rl;
  font-family: "Advent Pro", sans-serif;
  font-weight: bolder;
}
.navigation-mob
  .navigation-ul-mob
  .navigation-list-mob.active
  .navigation-link-mob
  .navigation-text-mob {
  opacity: 1;
  transform: translatex(20px);
}
.indicator-mob {
  position: absolute;
  width: 70px;
  height: 70px;
  top: 68px;
  right: -48px;
  background: rgb(2, 54, 16);
  background: linear-gradient(
    270deg,
    rgba(2, 54, 16, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );

  border-radius: 50%;
  border: 2px solid rgb(255, 255, 255);
  transition: 0.5s;
}

.navigation-mob
  .navigation-ul-mob
  .navigation-list-mob:nth-child(1).active
  ~ .indicator-mob {
  transform: translateX(calc(70px * 0));
}
.navigation-mob
  .navigation-ul-mob
  .navigation-list-mob:nth-child(2).active
  ~ .indicator-mob {
  transform: translatey(calc(70px * 1));
}
.navigation-mob
  .navigation-ul-mob
  .navigation-list-mob:nth-child(3).active
  ~ .indicator-mob {
  transform: translatey(calc(70px * 2));
}
.navigation-mob
  .navigation-ul-mob
  .navigation-list-mob:nth-child(4).active
  ~ .indicator-mob {
  transform: translatey(calc(70px * 3));
}
.navigation-mob
  .navigation-ul-mob
  .navigation-list-mob:nth-child(5).active
  ~ .indicator-mob {
  transform: translatey(calc(70px * 4));
}

.navigation-icons-mob {
  position: absolute;
  bottom: 10px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  height: 13%;
  justify-content: space-around;
  width: 100%;
}

.navigation-icons-mob-icon-wrapper a {
  cursor: pointer;
  color: black;
  height: 24px;
  width: 24px;
  border: 1px solid black;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
